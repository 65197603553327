.knicks-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.knicks-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

.project-description {
  text-align: center;
  margin-bottom: 20px;
}

.technical-tools-title {
  font-size: 1.5em;
  margin-top: 30px;
}

.technical-tools-list {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.image-item {
  position: relative;
  flex: 1 1 calc(33.333% - 10px);
  max-width: calc(33.333% - 10px);
}

.image-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-item:hover .overlay {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
}

.modal img {
  max-width: 70%;  /* Adjusted to make the image smaller */
  max-height: 70%; /* Adjusted to maintain aspect ratio */
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  background-color: rgba(86, 71, 71, 0.5);
  border-radius: 50%;
  padding: 0 .45rem;
  z-index: 1100;
}

@media (max-width: 768px) {
  .image-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .image-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.projects-layout {
  text-align: center;
  margin-bottom: 20px;
}

.projects-title {
  font-size: 2rem;
  font-weight: bold;
  color: #070707;
  text-align: center;
  font-family: "Raleway", sans-serif;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* Even spacing between boxes */
  border: 3em solid transparent; 
  font-family: "Raleway", sans-serif;
}

.box {
  position: relative; 
  flex: 0 1 80%; /* Base width of 30% */
  max-width: 375px; 
  height: 375px; 
  color: white;
  border: 10px solid black; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  font-size: 0.75rem; 
  font-weight: bold; 
  overflow: hidden; 
  margin: 10px; 
  text-align: center; 
  opacity: 0.8; 
  animation: fadeIn 3s ease-in forwards; 
  background-size: contain;
  background-position: center; 
  background-repeat: no-repeat; 
  font-family: "Raleway", sans-serif;
  background-color: yellowgreen;
}

.box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%; 
  background-color: rgba(128, 128, 128, 0.1); 
}

.box-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  /* color: rgba(10, 21, 225, 0.9); */
  color: rgb(24, 21, 21);
  font-weight: bolder;
  font-size: 1.5rem;
  transition: opacity 0.3s ease;
}

.overlay {
  position: absolute; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.3);
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  color: rgb(15, 15, 15); 
  font-weight: bolder;
  opacity: 0; 
  transition: opacity 0.3s ease; 
}

.box:hover .overlay {
  opacity: 1; 
}

.box:hover .box-title {
  opacity: 0; /* Hide title on hover */
}

.box:hover {
  transform: scale(1.05); 
}

button {
  height: 40px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
}

button:hover {
  transform: scale(1.1);
  background-color: #45a049;
}

button:focus {
  outline: none;
  box-shadow: 0 0 5px #4CAF50;
}

@media (max-width: 768px) {
  .box {
    flex: 0 1 100%; /* Full width on smaller screens */
    max-width: none; 
    margin: 10px 0; 
  }
}

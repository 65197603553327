.summary-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  margin-bottom: 10rem;
  padding: 20px;
  padding-bottom: 0;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto; 
}

.summary-title {
  font-size: 2rem;
  margin-bottom: 15px;
  text-align: center;
}

.summary-description {
  font-size: 1.2rem;
  text-align: center;
  max-width: 600px;
}
